import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

export default function getGroupedTestAdminClassResults(groupId) {
	return fetch(
		`${ENDPOINT_URL}/tests/${groupId}/groupedclasstests`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}