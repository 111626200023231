import {ENDPOINT_URL, language} from '../constants';

import {updateObject} from '../utils/reducerUtils';
import getFetchOptions from '../utils/getFetchOptions';
import {apiErrorHandling} from '../utils/apiUtils';

export default function editTestAdminSubscription(subscriptionUpdates) {
	let updatesObject = updateObject(
		{adminUserId: subscriptionUpdates.id},
		subscriptionUpdates,
		
	);
	
	delete updatesObject.id;
	updatesObject['lang'] ='swedish';
	return fetch(
		`${ENDPOINT_URL}/subscription`,
		getFetchOptions({
			method: 'PATCH',
			body: JSON.stringify(updatesObject),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
