import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';
class DumpTests extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const redundantTests = this.props.testsForDumping;
		const {isForSeView} = this.props;
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>
						{!isForSeView
							? 'Slett gamle og slettede tester'
							: 'SE Norming Tests'}
					</h3>
					<table className="lit-tests">
						<thead>
							<tr>
								<th className="lit-test__header">
									{`${strings.firstname}, ${strings.lastname}`}
								</th>
								{isForSeView && (
									<th className="lit-test__header">
										{`${strings.subscription}`}
									</th>
								)}

								{isForSeView && (
									<th className="lit-test__header">
										{`Token`}
									</th>
								)}
								<th className="lit-test__header">
									{strings.testkey}
								</th>
								<th className="lit-test__header">
									{strings.type}
								</th>
								{!isForSeView && (
									<th className="lit-test__header">
										{'Fakturert'}
									</th>
								)}
								{!isForSeView && (
									<th className="lit-test__header">
										{strings.usedon}
									</th>
								)}

								<th className="lit-test__header">
									{strings.completed}
								</th>
								{!isForSeView && (
									<th className="lit-test__header">
										{strings.reason}
									</th>
								)}
								{!isForSeView && (
									<th className="lit-test__header">
										{strings.action}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{redundantTests.map((redundantTest) => {
								return (
									<tr
										className="lit-list__item"
										key={redundantTest.id}
									>
										<td className="lit-test__col">
											{!redundantTest.firstName ||
											!redundantTest.lastName
												? 'Not Registered'
												: `${redundantTest.firstName}, ${redundantTest.lastName}`}
										</td>
										{isForSeView && (
											<td className="lit-test__col">
												{!redundantTest.teacherId
													? '--Unknown---'
													: `${redundantTest.teacherId}`}
											</td>
										)}

										{isForSeView && (
											<td className="lit-test__col">
												{!redundantTest.teacherId
													? '--Unknown---'
													: `${redundantTest.token}`}
											</td>
										)}
										<td className="lit-test__col">
											{redundantTest.keyString}
										</td>
										<td className="lit-test__col">
											{redundantTest.type}
										</td>
										{!isForSeView &&
										redundantTest.isCompleted &&
										Object.hasOwn(
											redundantTest,
											'lastInvoicedAt'
										) &&
										moment(
											redundantTest.completedAt
										).format('YYYY-MM-DD HH:mm:ss') <
											moment(
												redundantTest.lastInvoicedAt
											).format('YYYY-MM-DD HH:mm:ss') ? (
											<td className="lit-list__col">
												<div className="tickmark large">
													L
												</div>
											</td>
										) : !redundantTest.isCompleted &&
										  !isForSeView ? (
											<td className="lit-list__col">
												<div className="continue large">
													./..
												</div>
											</td>
										) : !isForSeView ? (
											<td className="lit-list__col">
												<div className="crossmark large">
													X
												</div>
											</td>
										) : null}
										{!isForSeView && (
											<td className="lit-test__col">
												{moment(
													redundantTest.usedAt
												).format('LL')}
											</td>
										)}
										{redundantTest.isCompleted ? (
											<td className="lit-list__col">
												<div className="tickmark large">
													L
												</div>
											</td>
										) : (
											<td className="lit-list__col">
												<div className="crossmark large">
													X
												</div>
											</td>
										)}

										{!isForSeView &&
										redundantTest.deleted ? (
											<td className="lit-test__col">
												{strings.testdeletedbyuser}
											</td>
										) : !isForSeView ? (
											<td className="lit-test__col">
												{strings.testolderthan3years}
											</td>
										) : null}
										{!isForSeView && (
											<td className="lit-test__col">
												<button
													className="lit-btn bg-negative"
													onClick={() => {
														if (
															window.confirm(
																strings.warning_deleteSingleTest
															)
														) {
															this.props.onDumpSingleTest(
																redundantTest.id,
																redundantTest.type,
																redundantTest.keyString
															);
														}
													}}
												>
													{strings.delete}
												</button>
											</td>
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default DumpTests;
