import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

//removing sub_id from api func.
export default function createSeTests(testUser) {
	return fetch(
		`${ENDPOINT_URL}/sepayload`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				testUser,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
