import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

const lang = 'se';
export default function getAllTestResults(type) {
	return fetch(
		`${ENDPOINT_URL}/test/results?type=${type}&lang=${lang}`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
